body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.agencyLogo {
  object-fit: contain;
  width:100%;

}

.menu {
  list-style-type: none;
  padding-left: 15px;
}

.menuRow {
  padding-left: 2px;
}

.menuIcon {
  font-weight: bold;
  padding-right: 5px;
}

.menuHeading {
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: bold;
  margin-left: -25px;
  line-height: 1.5;
}

.footer {
  background-color: rgba(78, 163, 9, 1);
  color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.bigboi {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: auto;
  height: 100vh;
}



.pageContent {

}

.breadcrumb {
  width: 100%;
}

.iframe {
  flex-grow: 1; border: none; margin: 0; padding: 0;
}

.iframePageContent {
  display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;
}


.headerIcons {
  display: inline-block;
  align-self: flex-end;
  text-align:right;
}

.headerIcon {
  font-size: 1.5em;
  cursor: pointer;
}

.headerIconWrapper {
  padding-right: 20px;
}

.editorIcon {
  font-size: 2.0em;
  cursor: pointer;
}

.smallBadge {
  font-size: .5em;
}


.menuLink a{
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.5;
  font-size: 95%;
  color: rgba(0,0,0,.65);
}

.div-wrapper {
  height: 200px;
  margin-top: 40px;
  border: 2px dashed #ddd;
}

.div-to-align {
  width: 75%;
  padding: 40px 20px;

  /* .... */
}

.todo {
  padding: .5rem;
  line-height: 3;
}

#root {
  height: 100vh;
}

.panel {

}

#cover {
  background: #222 center center no-repeat;
  background-size: cover;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
}

#cover-caption {
  width: 100%;
  position: relative;
  z-index: 1;
}

/* only used for background overlay not needed for centering */
form:before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  border: thin solid white;
  z-index: -1;
}

.link-button {
  display: inline;
  padding: 0;
  border: 0;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  color: currentColor;

  -webkit-appearance: none;
}

.info-icon {
  color: #1988ff;
  font-size: 2.0em;
}

.unreadmessage {
  font-weight: bold;
}
